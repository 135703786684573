module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-157267651-1","head":true,"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/006-box.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"gowdx","path":"/preview","previews":true,"defaultLang":"en-us","langs":["en-us","ru"],"pages":[{"type":"BlogPage","match":"/:lang?/blog","path":"/","component":"/opt/jenkins/workspace/WDX-PROD/wdx-landing-build-prod/src/templates/blog.js"},{"type":"BlogPost","match":"/:lang?/blog/:uid","path":"/","component":"/opt/jenkins/workspace/WDX-PROD/wdx-landing-build-prod/src/templates/blog-post.js"},{"type":"LandingPage","match":"/:lang?/landing/:uid","path":"/","component":"/opt/jenkins/workspace/WDX-PROD/wdx-landing-build-prod/src/templates/landing-page.js"},{"type":"ContentPage","match":"/:lang?/:uid","path":"/","component":"/opt/jenkins/workspace/WDX-PROD/wdx-landing-build-prod/src/templates/content-page.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
